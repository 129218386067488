import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Minus, Plus } from 'app/components/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  ctas?: ButtonProps[]
  description?: string
  title?: string
}

export const Item = memo(function Item({ ctas, description, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container activeClassName="active">
      <Head>
        <Title>
          <Inner>{title}</Inner>
          <Wrap>
            <State>{({ expanded }) => (expanded ? <Minus /> : <Plus />)}</State>
          </Wrap>
        </Title>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <Content>
                      <Description
                        dangerouslySetInnerHTML={{ __html: description }}
                      />

                      {ctas && ctas.length ? (
                        <Buttons>
                          {ctas.map((item, index) => (
                            <CTA>
                              <Button key={index} {...item} />
                            </CTA>
                          ))}
                        </Buttons>
                      ) : null}
                    </Content>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight5};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  position: relative;
  transition: 0.3s cubic-bezier(0, 0.55, 0.45, 1);
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.875rem;
  padding: 1.5625rem 1.875rem;

  @media (max-width: 374px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
`

const Inner = styled.div`
  width: calc(100% - 1.875rem);
`

const Wrap = styled.div`
  margin-left: auto;

  svg {
    width: auto;
    height: 0.875rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight5};
    stroke-width: 2;
  }

  @media (max-width: 1199px) {
    svg {
      height: 1rem;
    }
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  line-height: 1.875rem;
  overflow: hidden;
  padding: 0 1.875rem;

  @media (max-width: 374px) {
    padding: 0 1.5rem;
  }
`

const Content = styled.div`
  padding-bottom: 2.25rem;
`

const Description = styled.div``

const Buttons = styled.div`
  margin-top: 3rem;
`

const CTA = styled.div`
  margin-top: 0.125rem;
`
