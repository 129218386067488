import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  subtitle?: string
  title?: string
}

export const Directions = memo(function Directions({
  items,
  subtitle,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container className="blended" id="directions">
      <Blended>
        <Head>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {subtitle ? (
            <FadeInUp>
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
        </Head>

        <Accordion allowZeroExpanded>
          {items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </Accordion>
      </Blended>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 9.375rem 17.5vw;
  position: relative;

  .accordion {
    > div {
      &.active {
        background: ${({ theme }) => theme.colors.variants.neutralLight5};
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
        position: relative;

        svg {
          stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
        }
      }
    }
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    padding: 5.625rem 1.5rem;
  }
`

const Blended = styled.div`
  mix-blend-mode: difference;

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    mix-blend-mode: luminosity;
  }
`

const Head = styled.div`
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight5};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin-bottom: 1.875rem;
  padding-bottom: 4.375rem;
  padding-left: 1.25rem;

  @media (max-width: 1199px) {
    padding-bottom: 1.3125rem;
    padding-left: 0.9375rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`

const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  line-height: 1.875rem;
  margin-top: 0.375rem;
`
