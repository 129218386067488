import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  label: string
  xAxis: string
  yAxis: string
}

export const Poi = memo(function Poi({ label, xAxis, yAxis }: Props) {
  return (
    <Container
      style={{
        top: `${yAxis.replace(',', '.')}%`,
        left: `${xAxis.replace(',', '.')}%`,
      }}
    >
      <Tooltip>{label}</Tooltip>
    </Container>
  )
})

const Container = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  &:hover {
    &:before {
      animation: zoomIn 0.8s;
      width: 2.25rem;
      height: 2.25rem;
      background: none;
      border: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight5};
    }
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralLight5};
    }

    > div {
      opacity: 1;
      transform: translate(-50%, -1.375rem);
      visibility: visible;
      z-index: 1;
    }
  }
  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s cubic-bezier(0, 0.55, 0.45, 1);
  }
  &:before {
    width: 1.125rem;
    height: 1.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
  }
  &:after {
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    transform: translate(-50%, -50%);
  }

  @keyframes zoomIn {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @media (max-width: 1199px) {
    &:before {
      width: 2.25rem;
      height: 2.25rem;
      background: none;
      border: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight5};
    }
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralLight5};
    }
  }
`

const Tooltip = styled.div`
  height: 2.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding: 0.1875rem 0.75rem;
  opacity: 0;
  position: absolute;
  top: -1.875rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s cubic-bezier(0, 0.55, 0.45, 1);
  visibility: hidden;
  z-index: -1;
  white-space: nowrap;

  @media (max-width: 1199px) {
    opacity: 1;
    transform: translate(-50%, -1.375rem);
    visibility: visible;
    z-index: 1;
  }
`
